import axios from "axios";
import axiosInstance from "./axiosInstance";

class HomeApi {
  cancelTokens = {
    getSistemasData: null,
    getUrlsData: null,
    validarAcesso: null,
    abrirNovaUrl: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getSistemasAdminData(idUsuario) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getSistemasAdminData = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/home/sistemasAdminData`,
      {
        params: {
          idUsuario,
        },
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getSistemasAdminData = null;

    return data;
  }

  async getSistemasPublicosData(idUsuario) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getSistemasPublicosData = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/home/sistemasPublicosData`,
      {
        params: {
          idUsuario,
        },
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getSistemasPublicosData = null;

    return data;
  }

  async getUrlsData(identificadorSistema, ambiente, flag) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getUrlsData = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/home/urls_sistemas`,
      {
        params: {
          identificadorSistema,
          ambiente,
          flag
        },
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getUrlsData = null;

    return data; 
  }

  async validarAcesso(sistemaSigla) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.validarAcesso = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/home/validar_acesso`,
      {
        params: {
          sistemaSigla,
        },
        cancelToken: source.token,
      }
    );

    this.cancelTokens.validarAcesso = null;

    return data;
  }

  async abrirNovaUrl(usuarioLogado, sistemaSigla, sistemaUrl) {
    const login = usuarioLogado.token[0].usuario.login;
    const authPrivate = usuarioLogado.auth_private;
    const authPublic = usuarioLogado.auth_public;
  
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/home/gerar`,
        {
          login,
          auth_private: authPrivate,
          auth_public: authPublic,
          sistema_sigla: sistemaSigla,
        }
      );
  
      const tokenCriptografado = encodeURIComponent(response.data.token);
      const iv = encodeURIComponent(response.data.iv);
  
      // Extrai a base do sistema (http://localhost:3010)
      const sistemaBaseUrl = new URL(sistemaUrl).origin;
      
      // Mantém a URL completa de redirecionamento (incluindo caminho)
      const urlRedirecionamento = sistemaUrl;

      let authUrl
      if((sistemaBaseUrl + '/') === urlRedirecionamento) {
        authUrl = `${sistemaBaseUrl}/sessoes/autenticar?token=${tokenCriptografado}&iv=${iv}`;
      } else {
        authUrl = `${sistemaBaseUrl}/sessoes/autenticar?token=${tokenCriptografado}&iv=${iv}&redirect=${encodeURIComponent(urlRedirecionamento)}`;
      }
  
      // Abre em nova aba
      const novaAba = window.open(authUrl, '_blank');
      
      if (!novaAba || novaAba.closed) {
        alert("Por favor, permita pop-ups para este site");
      }
      
    } catch (error) {
      console.error('Erro ao gerar o token temporário:', error);
      alert('Erro ao acessar o sistema. Por favor, tente novamente.');
    }
  }

  async getExpiraEm() {
    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/home/expira_em_minutos`
    );

    return data;
  }
}

export default HomeApi;
