<template>
  <div>
    <div class="tela-login">
      <div class="logo_mobile">
        <img src="../../assets/imagens/logo-portal-bco.svg" alt=" " />
      </div>
      <div class="right">
        <h1>Acesso</h1>
        <form @submit="autenticar">
          <p v-show="erro" class="erro">{{ erro }}</p>
          <div class="item">
            <the-mask
              :mask="['###.###.###-##']"
              v-model="login"
              placeholder="CPF"
              autocomplete="username"
            />
          </div>
          <div class="item">
            <input
              type="password"
              v-model="senha"
              placeholder="Senha"
              autocomplete="current-password"
            />
          </div>
          <div class="item">
            <!--<a href="/" class="recuperar">Esqueci minha senha</a>-->
            <router-link :to="{ name: 'RecuperarSenha' }">
              Esqueci minha senha
            </router-link>
          </div>
          <div class="bt-entrar">
            <button
              type="submit"
              class="entrar"
              :class="{ 'is-loading': autenticando }"
              :disabled="autenticando"
            >
              <div v-if="autenticando">
                <img
                  src="../../assets/imagens/loadingSpinner.gif"
                  alt="Roda de carregamento girando"
                />
                Carregando
              </div>
              <div v-else>
                Entrar
              </div>
            </button>
          </div>
        </form>
      </div>
      <div class="footer-mobile">
        <img
          src="../../assets/imagens/logo-casa-bco2.svg"
          class="logo"
          alt="Logo da Casa Civil e Estado de Goiás"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Auth from "../../services/Auth";
import { rotaInicial } from "../../router/helpers";
import metaDados from "../../helpers/metaDados";

export default {
  name: "Autenticar",
  metaInfo() {
    return {
      title: "Portal",
      titleTemplate: "%s - Casa Civil do Estado de Goiás",
      meta: [
        {
          vmid: "description",
          name: "description",
          property: "description",
          content:
            "Página de acesso e cadastro de cessões/disposições de servidores.",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "Página de acesso e cadastro de cessões/disposições de servidores.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: "Portal - Casa Civil do Estado de Goiás",
        },
        ...metaDados(this.$route),
      ],
    };
  },
  data() {
    return {
      login: null,
      senha: null,
      autenticando: false,
      erro: null,
    };
  },
  created() {
    this.auth = new Auth();

    const query = this.$route.query;
    if (query.error) {
      this.erro = decodeURIComponent(query.error);
    }
  },
  methods: {
    async autenticar(event) {
      try {
        event.preventDefault();
        this.autenticando = true;
        this.erro = null;
        await this.auth.login(this.login, this.senha);
        this.autenticando = false;

        if (this.$route.query.redirect) {
          this.$router.push({
            name: "Home",
            query: {
              redirect: this.$route.query.redirect,
            },
          });
        } else {
          const rota = rotaInicial();

          this.$router.push({
            name: rota.name,
          });
        }
      } catch (err) {
        console.log(err);
        this.erro =
          typeof err === "string"
            ? err
            : "Houve um erro ao fazer login. Por favor tente novamente";
        this.autenticando = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.erro {
  color: red;
  text-align: center;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

.tela-login {
  padding: 1rem 0;
}

@media screen and (min-width: 800px) {
  .tela-login {
    display: flex;
    flex-direction: column;
    background-color: #125b19;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    margin: 0;

    .logo_mobile {
      img {
        width: 10rem;
        margin: 0 0 0.5rem 0;
      }
    }

    .right {
      background-color: white;
      //max-width: 22rem;
      padding: 1rem;
      width: 90%;
      max-width: 45rem;
      height: max-content;
      border-radius: 2rem;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.61);

      h1 {
        margin: 1rem 0 1rem 0;
        font-family: "Montserrat-Bolder";
        color: #00a339;
        text-align: center;
        font-size: calc(1.2rem + 1vw);
      }

      .item {
        display: grid;
        margin: 1rem 0;

        label {
          font-family: "Montserrat-Medium";
          color: #005516;
        }

        input:focus {
          outline: none;
          box-shadow: rgba(0, 0, 0, 0.404) 0 0 0.3rem;
        }

        input {
          height: 2.5rem;
          width: 100%;
          padding-left: 0.5rem;
          border: silver 0.1rem solid;
          border-radius: 3rem;
          font-family: "Montserrat";
          font-size: 1.1rem;
        }
      }

      a {
        margin: 0 0 0 0.5rem;
        color: #005516;
        text-align: left;
        font-size: 1rem;
        width: fit-content;
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }

      .bt-entrar {
        text-align: center;
        margin: 1.5rem 0 1rem 0;

        button {
          background-color: #005516;
          color: white;
          font-family: "Montserrat-Medium";
          font-size: 1.1rem;
          border: none;
          padding: 0.5rem 1.5rem;
          border-radius: 1rem;
          cursor: pointer;
          max-width: max-content;
          min-width: 10rem;
          width: fit-content;
        }

        button:hover {
          background-color: #005517d5;
        }

        button:disabled {
          background-color: rgba(71, 69, 69, 0.455);
        }

        img {
          width: 1.3rem;
          height: 1.3rem;
          margin-right: 0.4rem;
        }
      }
    }

    .footer-mobile {
      img {
        width: 32rem;
        padding: 0 0.3rem;
        margin: 1rem 0 0 0;
      }
    }
  }
}
@media screen and(min-width: 500px) and (max-width: 799px) {
  .tela-login {
    display: grid;
    background-color: #125b19;
    min-height: 100vh;
    justify-items: center;
    align-content: space-evenly;

    .logo_mobile {
      img {
        width: 10rem;
        margin: 0;
      }
    }

    .right {
      display: grid;
      gap: 0.5rem;
      background-color: white;
      padding: 1rem;
      width: 90%;
      max-width: 45rem;
      height: 22rem;
      border-radius: 2rem;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.61);
      align-content: center;

      h1 {
        font-family: "Montserrat-Bolder";
        color: #00a339;
        text-align: center;
        font-size: calc(1.2rem + 1vw);
      }

      form {
        display: grid;
      }

      .item {
        display: grid;
        margin: 1rem 0;

        label {
          font-family: "Montserrat-Medium";
          color: #005516;
        }

        input:focus {
          outline: none;
          box-shadow: rgba(0, 0, 0, 0.404) 0 0 0.3rem;
        }

        input {
          height: 2.5rem;
          width: 100%;
          padding-left: 0.5rem;
          border: silver 0.1rem solid;
          border-radius: 3rem;
          font-family: "Montserrat";
          font-size: 0.9rem;
        }
      }

      a {
        margin: 0 0 0 0.5rem;
        color: #005516;
        text-align: left;
        font-size: 0.9rem;
        width: fit-content;
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }

      .bt-entrar {
        text-align: center;
        margin: 1.5rem 0 1rem 0;

        button {
          background-color: #005516;
          color: white;
          font-family: "Montserrat-Medium";
          font-size: 1.1rem;
          border: none;
          padding: 0.5rem 1.5rem;
          border-radius: 1rem;
          cursor: pointer;
          min-width: 10rem;
          width: fit-content;
        }

        button:hover {
          background-color: #005517d5;
        }

        button:disabled {
          background-color: rgba(71, 69, 69, 0.455);
        }

        img {
          width: 1.3rem;
          height: 1.3rem;
          margin-right: 0.4rem;
        }
      }
    }

    .footer-mobile {
      img {
        width: 28rem;
        margin: 0.5rem 0 0 -0.3rem;
      }
    }
  }
}
@media screen and(min-width: 60px) and (max-width: 499px) {
  .tela-login {
    display: grid;
    background-color: #125b19;
    min-height: 100vh;
    justify-items: center;
    align-content: center;
    align-content: space-evenly;

    .logo_mobile {
      img {
        width: 8rem;
        margin: 0;
      }
    }

    .right {
      display: grid;
      gap: 0.5rem;
      background-color: white;
      padding: 1rem;
      width: 90%;
      max-width: 45rem;
      height: 20rem;
      border-radius: 2rem;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.61);
      align-content: center;

      h1 {
        font-family: "Montserrat-Bolder";
        color: #00a339;
        text-align: center;
        font-size: calc(1.2rem + 1vw);
      }

      form {
        display: grid;
      }

      .item {
        display: grid;
        margin: 0.5rem 0;

        label {
          font-family: "Montserrat-Medium";
          color: #005516;
        }

        input:focus {
          outline: none;
          box-shadow: rgba(0, 0, 0, 0.404) 0 0 0.3rem;
        }

        input {
          height: 2.5rem;
          width: 100%;
          padding-left: 0.5rem;
          border: silver 0.1rem solid;
          border-radius: 3rem;
          font-family: "Montserrat";
          font-size: 0.9rem;
        }
      }

      a {
        margin: 0 0 0 0.5rem;
        color: #005516;
        text-align: left;
        font-size: 0.9rem;
        width: fit-content;
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }

      .bt-entrar {
        text-align: center;
        margin: 1rem 0;

        button {
          background-color: #005516;
          color: white;
          font-family: "Montserrat-Medium";
          font-size: 1.1rem;
          border: none;
          padding: 0.5rem 1.5rem;
          border-radius: 1rem;
          cursor: pointer;
          min-width: 10rem;
          width: fit-content;
        }

        button:hover {
          background-color: #005517d5;
        }

        button:disabled {
          background-color: rgba(71, 69, 69, 0.455);
        }

        img {
          width: 1.3rem;
          height: 1.3rem;
          margin-right: 0.4rem;
        }
      }
    }

    .footer-mobile {
      img {
        width: 23rem;
        margin: 0.5rem 0 0 -0.3rem;
      }
    }
  }
}
</style>
